<template>
  <div class="login">
    <logo text="Login" />
    <b-card>
      <b-card-body>
        Login
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {

  components: {
  },
  data(){
    return {
    }
  },

}
</script>
